import React, { useEffect } from 'react'
import { AxiosPromise } from 'axios'
import { format, isToday } from 'date-fns'

import { Flex, Text, IconButton, FilterButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { requestOfReportsEmployeeColumn } from '@src/constants/columns/timeOff'
import { useTable } from '@src/components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import {
  useCurrentMonthRow,
  useCurrentWeekRow,
  useTimeOffCalendarControls,
  useTimeOffCalendarFilters,
} from '@src/features/TimeOffCalendarTable/hooks'
import { ChevronLeft, ChevronRight } from '@revolut/icons'
import { FieldOptions, GetRequestData } from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { EmployeeTimeOffRequestsCalendarInterface } from '@src/interfaces/timeOff'

interface DataEntity {
  id: number
  field_options: FieldOptions
}

type Props = {
  data: DataEntity
  weekMonthTab: 'Week' | 'Month'
  getItems: (
    requestData: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestData<EmployeeTimeOffRequestsCalendarInterface>>
  tableName: TableNames
  hideFilterButton?: boolean
  hidePolicy?: boolean
  initialFilters?: FilterByInterface[]
}

export const TimeOffCalendar = ({
  data,
  weekMonthTab,
  getItems,
  tableName,
  hideFilterButton,
  hidePolicy,
  initialFilters = [],
}: Props) => {
  const {
    currentDay,
    onClickNextWeek,
    onClickPrevWeek,
    onClickNextMonth,
    onClickPrevMonth,
    onClickToday,
  } = useTimeOffCalendarControls()

  const { startOfWeek, endOfWeek, getFilters } = useTimeOffCalendarFilters(
    weekMonthTab === 'Week',
    currentDay,
    initialFilters,
  )

  const table = useTable({ getItems }, getFilters())

  useEffect(() => {
    table.onFilterChange(getFilters())
  }, [weekMonthTab, currentDay])

  const canViewPolicyColumn =
    // since we use "team time-off calendar view" on the user's profile page, we can't always rely
    // on the entity-level permission value and sometimes have to pass it externally
    hidePolicy === undefined
      ? data.field_options?.permissions?.includes(
          PermissionTypes.ViewTimeOffRequestPolicy,
        )
      : hidePolicy

  const weekRow = useCurrentWeekRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )

  const monthRow = useCurrentMonthRow(
    currentDay,
    [
      {
        ...requestOfReportsEmployeeColumn,
        width: 220,
      },
    ],
    canViewPolicyColumn,
  )

  return (
    <Flex flexDirection="column" width="100%">
      {!hideFilterButton && (
        <Flex width="100%" alignItems="center" pb="s-24">
          <FilterButton onClick={onClickToday} active={isToday(currentDay)}>
            This {weekMonthTab === 'Week' ? 'week' : 'month'}
          </FilterButton>
        </Flex>
      )}
      <Flex width="100%" justifyContent="space-between" alignItems="center" pb="s-20">
        <IconButton
          useIcon={ChevronLeft}
          color={Token.color.greyTone50}
          onClick={weekMonthTab === 'Week' ? onClickPrevWeek : onClickPrevMonth}
          aria-label={weekMonthTab === 'Week' ? 'Previous week' : 'Previous month'}
        />
        {weekMonthTab === 'Week' ? (
          <Text variant="h5">
            {format(startOfWeek, 'dd MMM yyyy')} - {format(endOfWeek, 'dd MMM yyyy')}
          </Text>
        ) : (
          <Text variant="h5">{format(currentDay, 'MMMM yyyy')}</Text>
        )}
        <IconButton
          useIcon={ChevronRight}
          color={Token.color.greyTone50}
          onClick={weekMonthTab === 'Week' ? onClickNextWeek : onClickNextMonth}
          aria-label={weekMonthTab === 'Week' ? 'Next week' : 'Next month'}
        />
      </Flex>
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable<EmployeeTimeOffRequestsCalendarInterface>
          name={tableName}
          useWindowScroll
          {...table}
          row={weekMonthTab === 'Week' ? weekRow : monthRow}
          enableSettings={false}
          hideCountAndButtonSection
          rowHeight="large"
        />
      </Flex>
    </Flex>
  )
}
