import React from 'react'
import { format, addYears, subYears } from 'date-fns'
import pluralize from 'pluralize'
import { Box, H6, ItemSkeleton, Token, Widget } from '@revolut/ui-kit'
import { useGetRequestsOfReports } from '@src/api/timeOff'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  END_DATE_FILTER_KEY,
  START_DATE_FILTER_KEY,
} from '@src/features/TimeOffCalendarTable/constants'
import { ApprovalItem } from '@src/pages/EmployeeProfile/Layout/TimeOff/ApprovalsWidget/ApprovalItem'

type Props = {
  data: EmployeeInterface
}

export const ApprovalsWidget = ({ data }: Props) => {
  const startDateFilter = format(subYears(Date.now(), 1), 'yyyy-MM-dd')
  const endDateFilter = format(addYears(Date.now(), 1), 'yyyy-MM-dd')

  const {
    data: pendingApprovalRequests,
    isLoading: isLoadingPendingApprovalRequests,
    refetch: refetchPendingApprovalRequests,
  } = useGetRequestsOfReports(data.id, {
    filters: [
      { columnName: 'approval_status', filters: [{ id: 'pending', name: 'pending' }] },
      {
        filters: [
          {
            id: startDateFilter,
            name: startDateFilter,
          },
        ],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [
          {
            id: endDateFilter,
            name: endDateFilter,
          },
        ],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
    ],
    sortBy: [
      {
        sortBy: 'from_date_time',
        nonResettable: true,
      },
    ],
  })

  if (isLoadingPendingApprovalRequests) {
    return (
      <Widget>
        <ItemSkeleton />
      </Widget>
    )
  }
  if (!pendingApprovalRequests?.results.length) {
    return null
  }

  const pendingApprovalRequest = pendingApprovalRequests.results[0]
  const hasTimePeriodData = !!(
    pendingApprovalRequest.from_date_time && pendingApprovalRequest.to_date_time
  )

  return (
    <>
      <Box px="s-16" pt="s-16">
        <H6 color={Token.color.greyTone50}>
          Showing {pluralize('request', pendingApprovalRequests.results.length, true)}
        </H6>
      </Box>
      <Widget>
        {hasTimePeriodData && (
          <ApprovalItem
            employee={pendingApprovalRequest.employee as any} // TODO fix
            key={pendingApprovalRequest.id}
            refetchData={async () => {
              await refetchPendingApprovalRequests()
            }}
            request={pendingApprovalRequest as any} // TODO fix
          />
        )}
      </Widget>
    </>
  )
}
