import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, Flex, MoreBar, TableWidget, VStack } from '@revolut/ui-kit'
import {
  getDepartmentTimeOffCalendar,
  getRequestsOfReportsCalendar,
  getTeamTimeOffCalendar,
} from '@src/api/timeOff'
import { useGetTeam } from '@src/api/teams'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { ApprovalsWidget } from './ApprovalsWidget'

export const TimeManagement = () => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)

  const { data: teamData } = useGetTeam(user.team.id)

  const [showDepartment, setShowDepartment] = useState(false)
  const [showTeam, setShowTeam] = useState(false)

  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )
  const canViewTimeOffPolicies = permissions.includes(PermissionTypes.ViewTimeOffPolicies)
  const canViewTimeOffPreferences = permissions.includes(
    PermissionTypes.ViewTimeOffPreferences,
  )

  const { tabBar: periodTabBar, currentTab: periodCurrentTab } = useTabBarSwitcher({
    tabs: ['Week', 'Month'],
    defaultTab: 'Month',
    highlightSelected: false,
  })

  return (
    <VStack space="s-16">
      <ApprovalsWidget data={user} />
      <TableWidget>
        <TableWidget.Actions>
          <MoreBar>
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
                employeeId: user.id,
              })}
              use={InternalLink}
              useIcon="Plus"
            >
              Request time off
            </MoreBar.Action>
            {canViewTimeOffPolicies && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.APPS.TIME_OFF.POLICIES)}
                use={InternalLink}
                useIcon="Pencil"
              >
                Manage policies
              </MoreBar.Action>
            )}
            {canViewTimeOffPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.TIME_OFF.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </MoreBar.Action>
            )}
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Filters>
          <FilterButton
            active={showTeam}
            onClick={() => {
              setShowDepartment(false)
              setShowTeam(!showTeam)
            }}
          >
            My team
          </FilterButton>
          {teamData?.department?.id && (
            <FilterButton
              active={showDepartment}
              onClick={() => {
                setShowTeam(false)
                setShowDepartment(!showDepartment)
              }}
            >
              My department
            </FilterButton>
          )}
        </TableWidget.Filters>
        <TableWidget.Table>
          <VStack gap="s-16">
            <Flex>{periodTabBar}</Flex>
            {showTeam ? (
              <TimeOffCalendar
                key="teamTimeOffCalendar"
                data={user}
                getItems={getTeamTimeOffCalendar(user.team.id)}
                hideFilterButton
                hidePolicy={canViewTeamRequestsPolicy}
                tableName={TableNames.TeamTimeOffCalendar}
                weekMonthTab={periodCurrentTab || 'Month'}
              />
            ) : showDepartment && teamData?.department?.id ? (
              <TimeOffCalendar
                key="departmentTimeOffCalendar"
                data={user}
                getItems={getDepartmentTimeOffCalendar(teamData?.department?.id)}
                hideFilterButton
                tableName={TableNames.DepartmentTimeOffCalendar}
                weekMonthTab={periodCurrentTab || 'Month'}
              />
            ) : (
              <TimeOffCalendar
                key="employeesTimeOffCalendar"
                data={user}
                getItems={getRequestsOfReportsCalendar(user.id)}
                hideFilterButton
                tableName={TableNames.EmployeesTimeOffCalendar}
                weekMonthTab={periodCurrentTab || 'Month'}
              />
            )}
          </VStack>
        </TableWidget.Table>
      </TableWidget>
    </VStack>
  )
}
